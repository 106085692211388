<!--
 * @Description: 审核房费支出
 * @Author: 琢磨先生
 * @Date: 2022-08-08 04:02:58
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-08-08 07:07:12
-->


<template>
    <el-button type="primary" link size="small" v-if="model.status === 0 || model.status === 4" @click="openDialog">审核
    </el-button>
    <el-dialog title="房费审核" v-model="dialogVisible" width="800px" :append-to-body="true" :close-on-click-modal="false"
        :close-on-press-escape="false" :destroy-on-close="true">
        <el-descriptions title="费用详情">
            <el-descriptions-item label="订单类型">{{ model.order_type_text }}</el-descriptions-item>
            <el-descriptions-item label="房号">{{ model.store.house_no }}</el-descriptions-item>
            <el-descriptions-item label="房东">{{ model.store.landlord.name }}</el-descriptions-item>
            <el-descriptions-item label="总额">￥{{ model.decimal_total_amount }}</el-descriptions-item>
            <el-descriptions-item label="单价">{{ model.decimal_unit_price }}{{ model.expense_item_unit }}
            </el-descriptions-item>
            <el-descriptions-item label="业主支出">￥{{ model.decimal_landlord_amount }}</el-descriptions-item>
            <el-descriptions-item label="商户支出">￥{{ model.decimal_business_amount }}</el-descriptions-item>
            <el-descriptions-item label="数量">{{ model.number }}</el-descriptions-item>
            <el-descriptions-item label="支付日期">{{ model.pay_date }}</el-descriptions-item>
            <el-descriptions-item label="备注信息">{{ model.remark }}</el-descriptions-item>
            <el-descriptions-item label="创建时间">{{ model.create_at }}</el-descriptions-item>
        </el-descriptions>

        <el-divider direction="horizontal" content-position="left">附件图片</el-divider>

        <div class="image_list">
            <div class="image" v-for="(item, index) in model.pic_urls" :key="item">
                <el-image :src="item" fit="cover" style="width:100px;height:100px;" :preview-src-list="model.pic_urls"
                    :initial-index="index"></el-image>
            </div>
        </div>

        <el-descriptions title="订单详情" v-if="dtl.order">
            <el-descriptions-item label="订单号">{{ dtl.order.order_no }}</el-descriptions-item>
            <el-descriptions-item label="订单总额">￥{{ dtl.order.decimal_total_amount }}</el-descriptions-item>
            <el-descriptions-item label="房费总额">￥{{ dtl.order.decimal_amount }}</el-descriptions-item>
            <el-descriptions-item label="已付总额">￥{{ dtl.order.decimal_receipt_amount }}</el-descriptions-item>
        </el-descriptions>
        <el-skeleton :rows="5" v-if="loading" />
        <el-table v-if="dtl.order" :data="dtl.order.order_stores" border>
            <el-table-column label="房间" width="400">
                <template #default="scope">
                    <div>{{ scope.row.house_name }}</div>
                    <div>房号：{{ scope.row.house_no }}</div>
                </template>
            </el-table-column>
            <el-table-column label="房费">
                <template #default="scope">
                    <div>
                        {{ scope.row.checkin_date }} 至 {{ scope.row.checkout_date }}，{{
                                scope.row.days
                        }}晚
                    </div>
                    <div>
                        房费：￥{{ scope.row.decimal_cost_amount }}
                    </div>
                    <div>
                        违约金：￥{{ scope.row.decimal_break_amount }}
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-alert class="alert_expense_check" type="info" title="审核结果" :closable="false" style="margin-top:20px;">
            <el-form :model="form" ref="form" :rules="rules">
                <el-form-item label="结果" prop="check_result">
                    <el-radio-group v-model="form.check_result">
                        <el-radio :label="2">有效</el-radio>
                        <el-radio :label="4">无效</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="" style="margin-top: 20px">
                    <el-input v-model="form.check_remark" type="textarea" rows="4" placeholder="请输入审核备注信息"></el-input>
                </el-form-item>
            </el-form>
        </el-alert>

        <template #footer>
            <el-button type="primary" @click="onSubmit" :loading="saving">确定</el-button>
        </template>
    </el-dialog>

</template>

<script>
export default {
    data() {
        return {
            //加载数据中
            loading: true,
            dialogVisible: false,
            saving: false,
            //详情
            dtl: {},
            //表单提交数据
            form: {},
            rules: {
                check_result: [
                    { required: true, message: '必填', trigger: 'blur' }
                ]
            }
        }
    },
    props: ['model'],
    emits: ['change'],
    methods: {
        /**
         * 打开
         */
        openDialog() {
            this.dialogVisible = true;
            this.loadDtl();
            this.$http.get('seller/v1/expense/record/checking?id=' + this.model.id);
        },
        /**
         * 加载详情
         */
        loadDtl() {
            this.$http.get('seller/v1/expense/record/dtl?id=' + this.model.id).then(res => {
                if (res.code == 0) {
                    this.dtl = res.data;
                }
                this.loading = false;
            })
        },
        /**
         * @description: 
         * @return {*}
         */
        onSubmit() {
            this.form.id = this.model.id;
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.saving = true;
                    this.$http.post('seller/v1/expense/record/check', this.form).then(res => {
                        if (res.code == 0) {
                            this.$emit('change');
                            this.dialogVisible = false;
                        }
                        this.saving = false;
                    })
                }
            });
        }
    },
}
</script>

<style  scoped>
.image_list {
    display: flex;
    flex-wrap: wrap;
}

.image_list .image {
    margin-right: 10px;
    margin-bottom: 10px;
}
</style>
<style>
.alert_expense_check .el-alert__content {
    width: 100%;
}
</style>